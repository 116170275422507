.home-container {
  width: 100%;
  display: flex;
  overflow: hidden;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.home-navbar {
  width: 100%;
  display: flex;
  z-index: 100;
  align-items: center;
  flex-direction: column;
}
.home-navbar-interactive {
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  padding-top: var(--dl-space-space-threeunits);
  padding-left: var(--dl-space-space-oneandhalfunits);
  margin-bottom: var(--dl-space-space-twounits);
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-threeunits);
  justify-content: space-between;
  background-color: rgba(230, 249, 224,1);
}
.home-branding {
  gap: var(--dl-space-space-unit);
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.home-text {
  color: rgb(18, 40, 33);
  font-size: 54px;
  font-style: normal;
  font-weight: 400;
  line-height: 36px;
}
.home-desktop-menu {
  display: flex;
}
.home-links {
  gap: var(--dl-space-space-threeunits);
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.home-button {
  color: rgb(18, 40, 33);
  font-size: 18px;
  font-family: Lato;
  line-height: 27px;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: 0px;
  border-radius: 0px;
  padding-right: 0px;
  padding-bottom: var(--dl-space-space-halfunit);
  background-color: transparent;
}
.home-button1 {
  color: rgb(18, 40, 33);
  font-size: 18px;
  font-family: Lato;
  line-height: 27px;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: 0px;
  border-radius: 0px;
  padding-right: 0px;
  padding-bottom: var(--dl-space-space-halfunit);
  background-color: transparent;
}
.home-link {
  text-decoration: none;
}
.home-button2 {
  color: rgb(18, 40, 33);
  font-size: 18px;
  font-family: Lato;
  line-height: 27px;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: 0px;
  border-radius: 0px;
  padding-right: 0px;
  padding-bottom: var(--dl-space-space-halfunit);
  background-color: transparent;
}
.home-link1 {
  text-decoration: none;
}
.home-burger-menu {
  display: none;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.home-hamburger {
  width: 100px;
  object-fit: cover;
}
.home-mobile-menu {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  display: flex;
  z-index: 100;
  position: fixed;
  transform: translateX(100%);
  transition: 0.5s;
  padding-top: var(--dl-space-space-threeunits);
  padding-left: var(--dl-space-space-oneandhalfunits);
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-threeunits);
  justify-content: flex-start;
  background-color: #fff;
}
.home-top {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-threeunits);
  justify-content: space-between;
}
.home-branding1 {
  gap: var(--dl-space-space-unit);
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.home-text02 {
  color: rgba(18, 40, 33, 1);
  font-size: 24px;
  line-height: 36px;
}
.home-icon {
  width: 7px;
  height: 7px;
}
.home-text03 {
  color: rgba(18, 40, 33, 1);
  font-size: 18px;
  font-family: Lato;
  line-height: 27px;
}
.home-menu-close {
  display: flex;
  align-items: center;
  justify-content: center;
}
.home-icon02 {
  width: 34px;
  height: 34px;
}
.home-links1 {
  gap: var(--dl-space-space-unit);
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.home-button3 {
  color: var(--dl-color-gray-white);
  font-size: 18px;
  font-family: Lato;
  line-height: 27px;
  border-radius: 28px;
  background-color: rgb(18, 40, 33);
}
.home-button4 {
  color: var(--dl-color-gray-white);
  font-size: 18px;
  font-family: Lato;
  line-height: 27px;
  border-radius: 28px;
  background-color: rgb(18, 40, 33);
}
.home-button5 {
  color: var(--dl-color-gray-white);
  font-size: 18px;
  font-family: Lato;
  line-height: 27px;
  border-radius: 28px;
  background-color: rgb(18, 40, 33);
}
.home-social-bar {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-threeunits);
  margin-top: auto;
  justify-content: flex-start;
}
.home-icon04 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  margin-right: var(--dl-space-space-twounits);
}
.home-icon06 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  margin-right: var(--dl-space-space-twounits);
}
.home-icon08 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  margin-right: var(--dl-space-space-twounits);
}
.home-icon10 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  margin-right: var(--dl-space-space-twounits);
}
.home-icon12 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.home-hero {
  gap: var(--dl-space-space-threeunits);
  width: 100%;
  height: 787px;
  display: flex;
  position: relative;
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  padding-left: var(--dl-space-space-oneandhalfunits);
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: row;
}
.home-content {
  gap: var(--dl-space-space-unit);
  width: auto;
  display: flex;
  position: relative;
  max-width: 1280px;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-end;
}
.home-image {
  left: 0px;
  width: 240px;
  bottom: -410px;
  height: 330px;
  position: absolute;
  object-fit: cover;
}
.home-title {
  color: rgb(18, 40, 33);
  font-size: 72px;
  max-width: 600px;
  font-weight: 500;
  line-height: 92px;
}
.home-text06 {
  font-size: 60px;
  font-weight: 500;
}
.home-text07 {
  font-size: 66px;
  font-weight: 500;
}
.home-text08 {
  font-size: 46px;
  font-weight: 500;
}
.home-description {
  color: rgb(18, 40, 33);
  font-size: 24px;
  max-width: 600px;
  font-family: "Lato";
  line-height: 36px;
}
.home-image1 {
  width: 720px;
  height: 700px;
  display: flex;
  overflow-x: hidden;
  align-items: flex-end;
  flex-direction: row;
  justify-content: space-between;
  background-color: rgba(250, 228, 142, 1);
}
.home-image2 {
  width: 100%;
  height: 100%;
  display: flex;
  background-size: cover;
  background-image: url('https://s3.us-east-2.amazonaws.com/triplebrooknursery.com/assets/plants10.jpg');
  background-position: right;
}
.home-information {
  gap: var(--dl-space-space-threeunits);
  width: 100%;
  height: 854px;
  display: flex;
  position: relative;
  max-width: 1280px;
  align-items: flex-start;
  padding-top: 120px;
  padding-left: 290px;
  flex-direction: column;
  padding-bottom: 120px;
}
.home-button6 {
  margin-top: var(--dl-space-space-threeunits);
}
.home-text10 {
  line-height: 21px;
}
.home-container01 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.home-container02 {
  flex: 0 0 auto;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-heading {
  color: rgb(18, 40, 33);
  width: 280px;
  height: 153px;
  font-size: 40px;
  max-width: 1000px;
  align-self: flex-start;
  font-style: normal;
  margin-top: var(--dl-space-space-twounits);
  font-weight: 500;
  line-height: 51px;
}
.home-text17 {
  font-size: 24px;
}
.home-container03 {
  flex: 0 0 auto;
  width: 100px;
  height: 100%;
  display: flex;
  flex-grow: 3;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.home-iframe {
  width: 440px;
  height: 283px;
}
.home-gallery {
  width: 100%;
  height: 155px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(230, 249, 224,1);
}
.home-text33 {
  color: var(--dl-color-gray-500);
  font-size: 54px;
  font-style: normal;
  font-weight: 500;
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-twounits);
  border-radius: 12px;
  padding-right: var(--dl-space-space-twounits);
  padding-bottom: var(--dl-space-space-twounits);
}
.home-gallery1 {
  width: 100%;
  height: 1200px;
  display: grid;
  grid-gap: var(--dl-space-space-unit);
  max-width: var(--dl-size-size-maxwidth);
  align-items: flex-start;
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  padding-bottom: var(--dl-space-space-twounits);
  grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-template-areas: "a b b" "a b b" "a c d" "a c d"  "e e d" "e e f" "e e f";
  grid-template-columns: 1fr 1fr 1fr;
}
.home-container04 {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  opacity: 1;
  grid-area: a;
  transition: 0.3s;
  align-items: flex-start;
  flex-direction: column;
  background-size: cover;
  background-image: url('https://images.unsplash.com/photo-1522814208878-08129b4b76f3?ixid=Mnw5MTMyMXwwfDF8c2VhcmNofDF8fGxpZ2h0aG91c2UlMjBwYXN0ZWx8ZW58MHx8fHwxNjI2NDM5Nzkx&ixlib=rb-1.2.1&w=1400');
  background-position: center;
}
.home-container04:hover {
  transform: scale(0.98);
}
.home-container05 {
  width: 100%;
  height: 100%;
  display: flex;
  padding: var(--dl-space-space-unit);
  align-items: flex-start;
  flex-direction: column;
  background-size: cover;
  background-image: url('https://s3.us-east-2.amazonaws.com/triplebrooknursery.com/assets/plants16.jpg');
  background-position: left;
}
.home-text34 {
  color: var(--dl-color-gray-white);
  font-size: 1.5rem;
  font-weight: 600;
}
.home-container06 {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  grid-area: b;
  transition: 0.3s;
  align-items: flex-start;
  flex-direction: column;
  background-size: cover;
  background-image: url('https://images.unsplash.com/photo-1494438639946-1ebd1d20bf85?ixid=Mnw5MTMyMXwwfDF8c2VhcmNofDJ8fGdyZWVuJTIwbGFtcCUyMHBhc3RlbHxlbnwwfHx8fDE2MjY0Mzk4NDA&ixlib=rb-1.2.1&w=1400');
  background-position: center;
}
.home-container06:hover {
  transform: scale(0.98);
}
.home-container07 {
  width: 100%;
  height: 100%;
  display: flex;
  padding: var(--dl-space-space-unit);
  align-items: flex-start;
  flex-direction: column;
  background-size: cover;
  background-image: url('https://s3.us-east-2.amazonaws.com/triplebrooknursery.com/assets/pic3.jpg');
  background-position: center;
}
.home-text35 {
  color: var(--dl-color-gray-white);
  font-size: 1.5rem;
  font-weight: 600;
}
.home-container08 {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  grid-area: c;
  transition: 0.3s;
  align-items: flex-start;
  flex-direction: column;
  background-size: cover;
  background-image: url('https://images.unsplash.com/photo-1528696892704-5e1122852276?ixid=Mnw5MTMyMXwwfDF8c2VhcmNofDF8fHBpbmslMjB0ZWElMjBwYXN0ZWx8ZW58MHx8fHwxNjI2NDM5ODU2&ixlib=rb-1.2.1&w=1400');
  background-position: center;
}
.home-container08:hover {
  transform: scale(0.98);
}
.home-container09 {
  width: 100%;
  height: 100%;
  display: flex;
  padding: var(--dl-space-space-twounits);
  align-items: flex-start;
  flex-direction: column;
  background-size: cover;
  background-image: url('https://s3.us-east-2.amazonaws.com/triplebrooknursery.com/assets/plants10.jpg');
}
.home-text36 {
  color: var(--dl-color-gray-white);
  font-size: 1.5rem;
  font-weight: 600;
}
.home-container10 {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  grid-area: d;
  transition: 0.3s;
  align-items: center;
  flex-direction: column;
  background-size: cover;
  justify-content: center;
  background-image: url('https://images.unsplash.com/photo-1497034825429-c343d7c6a68f?ixid=Mnw5MTMyMXwwfDF8c2VhcmNofDV8fHllbGxvdyUyMGljZWNyZWFtfGVufDB8fHx8MTYyNjQzOTg4OQ&ixlib=rb-1.2.1&w=1400');
  background-position: center;
}
.home-container10:hover {
  transform: scale(0.98);
}
.home-container11 {
  width: 100%;
  height: 100%;
  display: flex;
  padding: var(--dl-space-space-unit);
  align-items: flex-start;
  flex-direction: column;
  background-size: cover;
  background-image: url('https://s3.us-east-2.amazonaws.com/triplebrooknursery.com/assets/IMG_9696.jpg');
}
.home-text37 {
  color: var(--dl-color-gray-white);
  font-size: 1.5rem;
  font-weight: 600;
}
.home-container12 {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  grid-area: e;
  transition: 0.3s;
  align-items: flex-start;
  flex-direction: column;
  background-size: cover;
  background-image: url('https://images.unsplash.com/photo-1551500226-b50b653e33e8?ixid=Mnw5MTMyMXwwfDF8c2VhcmNofDEwfHxwYXN0ZWwlMjBjb2ZmZWUlMjBncmVlbnxlbnwwfHx8fDE2MjY0Mzk5MzA&ixlib=rb-1.2.1&w=1400');
  background-position: center;
}
.home-container12:hover {
  transform: scale(0.98);
}
.home-container13 {
  width: 100%;
  height: 100%;
  display: flex;
  padding: var(--dl-space-space-unit);
  align-items: flex-start;
  flex-direction: column;
  background-size: cover;
  background-image: url('https://s3.us-east-2.amazonaws.com/triplebrooknursery.com/assets/about2.jpg');
}
.home-text38 {
  color: var(--dl-color-gray-white);
  font-size: 1.5rem;
  font-weight: 600;
}
.home-container14 {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  grid-area: f;
  transition: 0.3s;
  align-items: flex-start;
  flex-direction: column;
  background-size: cover;
  background-image: url('https://images.unsplash.com/photo-1591101761702-0c4927df1d52?ixid=Mnw5MTMyMXwwfDF8c2VhcmNofDh8fHBhc3RlbCUyMHBpbmslMjBjb3R0b258ZW58MHx8fHwxNjI2NDQwMDUw&ixlib=rb-1.2.1&w=1400');
  background-position: center;
}
.home-container14:hover {
  transform: scale(0.98);
}
.home-container15 {
  width: 100%;
  height: 100%;
  display: flex;
  padding: var(--dl-space-space-unit);
  align-items: flex-start;
  flex-direction: column;
  background-size: cover;
  background-image: url('https://s3.us-east-2.amazonaws.com/triplebrooknursery.com/assets/plants11.jpg');
  background-position: center;
}
.home-text39 {
  color: var(--dl-color-gray-white);
  font-size: 1.5rem;
  font-weight: 600;
}
.home-gallery2 {
  width: 100%;
  height: 1200px;
  display: grid;
  grid-gap: var(--dl-space-space-unit);
  max-width: var(--dl-size-size-maxwidth);
  align-items: flex-start;
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  padding-bottom: var(--dl-space-space-twounits);
  grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-template-areas: "a b b" "a b b" "a c d" "a c d"  "e e d" "e e f" "e e f";
  grid-template-columns: 1fr 1fr 1fr;
}
.home-container16 {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  opacity: 1;
  grid-area: a;
  transition: 0.3s;
  align-items: flex-start;
  flex-direction: column;
  background-size: cover;
  background-image: url("https://images.unsplash.com/photo-1522814208878-08129b4b76f3?ixid=Mnw5MTMyMXwwfDF8c2VhcmNofDF8fGxpZ2h0aG91c2UlMjBwYXN0ZWx8ZW58MHx8fHwxNjI2NDM5Nzkx&ixlib=rb-1.2.1&w=1000");
  background-position: center;
}
.home-container16:hover {
  transform: scale(0.98);
}
.home-container17 {
  width: 100%;
  border: 2px dashed rgba(120, 120, 120, 0.4);
  height: 100%;
  display: flex;
  padding: var(--dl-space-space-unit);
  align-items: flex-start;
  flex-direction: column;
  background-size: cover;
  background-image: url('https://s3.us-east-2.amazonaws.com/triplebrooknursery.com/assets/plants7.jpg');
  background-position: bottom;
}
.home-container18 {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  grid-area: b;
  transition: 0.3s;
  align-items: flex-start;
  flex-direction: column;
  background-size: cover;
  background-image: url("https://images.unsplash.com/photo-1494438639946-1ebd1d20bf85?ixid=Mnw5MTMyMXwwfDF8c2VhcmNofDJ8fGdyZWVuJTIwbGFtcCUyMHBhc3RlbHxlbnwwfHx8fDE2MjY0Mzk4NDA&ixlib=rb-1.2.1&h=1000");
  background-position: center;
}
.home-container18:hover {
  transform: scale(0.98);
}
.home-container19 {
  width: 100%;
  height: 100%;
  display: flex;
  padding: var(--dl-space-space-unit);
  align-items: flex-start;
  flex-direction: column;
  background-size: cover;
  background-image: url('https://s3.us-east-2.amazonaws.com/triplebrooknursery.com/assets/pic13.jpg');
  background-position: center;
}
.home-text40 {
  color: var(--dl-color-gray-white);
  font-size: 1.5rem;
  font-weight: 600;
}
.home-container20 {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  grid-area: c;
  transition: 0.3s;
  align-items: flex-start;
  flex-direction: column;
  background-size: cover;
  background-image: url("https://images.unsplash.com/photo-1528696892704-5e1122852276?ixid=Mnw5MTMyMXwwfDF8c2VhcmNofDF8fHBpbmslMjB0ZWElMjBwYXN0ZWx8ZW58MHx8fHwxNjI2NDM5ODU2&ixlib=rb-1.2.1&w=1000");
  background-position: center;
}
.home-container20:hover {
  transform: scale(0.98);
}
.home-container21 {
  width: 100%;
  height: 100%;
  display: flex;
  padding: var(--dl-space-space-twounits);
  align-items: flex-start;
  flex-direction: column;
  background-size: cover;
  background-image: url('https://s3.us-east-2.amazonaws.com/triplebrooknursery.com/assets/plants14.jpg');
}
.home-text41 {
  color: var(--dl-color-gray-white);
  font-size: 1.5rem;
  font-weight: 600;
}
.home-container22 {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  grid-area: d;
  transition: 0.3s;
  align-items: flex-start;
  flex-direction: column;
  background-size: cover;
  background-image: url("https://images.unsplash.com/photo-1497034825429-c343d7c6a68f?ixid=Mnw5MTMyMXwwfDF8c2VhcmNofDV8fHllbGxvdyUyMGljZWNyZWFtfGVufDB8fHx8MTYyNjQzOTg4OQ&ixlib=rb-1.2.1&h=1000");
  background-position: center;
}
.home-container22:hover {
  transform: scale(0.98);
}
.home-container23 {
  width: 100%;
  height: 100%;
  display: flex;
  padding: var(--dl-space-space-unit);
  align-items: flex-start;
  flex-direction: column;
  background-size: cover;
  background-image: url('https://s3.us-east-2.amazonaws.com/triplebrooknursery.com/assets/IMG_3418.jpg');
}
.home-text42 {
  color: var(--dl-color-gray-white);
  font-size: 1.5rem;
  font-weight: 600;
}
.home-container24 {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  grid-area: e;
  transition: 0.3s;
  align-items: flex-start;
  flex-direction: column;
  background-size: cover;
  background-image: url("https://images.unsplash.com/photo-1551500226-b50b653e33e8?ixid=Mnw5MTMyMXwwfDF8c2VhcmNofDEwfHxwYXN0ZWwlMjBjb2ZmZWUlMjBncmVlbnxlbnwwfHx8fDE2MjY0Mzk5MzA&ixlib=rb-1.2.1&w=1000");
  background-position: center;
}
.home-container24:hover {
  transform: scale(0.98);
}
.home-container25 {
  width: 100%;
  height: 100%;
  display: flex;
  padding: var(--dl-space-space-unit);
  align-items: flex-start;
  flex-direction: column;
  background-size: cover;
  background-image: url('https://s3.us-east-2.amazonaws.com/triplebrooknursery.com/assets/plants3.jpg');
  background-position: top;
}
.home-text43 {
  color: var(--dl-color-gray-white);
  font-size: 1.5rem;
  font-weight: 600;
}
.home-container26 {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  grid-area: f;
  transition: 0.3s;
  align-items: flex-start;
  flex-direction: column;
  background-size: cover;
  background-image: url("https://images.unsplash.com/photo-1591101761702-0c4927df1d52?ixid=Mnw5MTMyMXwwfDF8c2VhcmNofDh8fHBhc3RlbCUyMHBpbmslMjBjb3R0b258ZW58MHx8fHwxNjI2NDQwMDUw&ixlib=rb-1.2.1&w=1000");
  background-position: center;
}
.home-container26:hover {
  transform: scale(0.98);
}
.home-container27 {
  width: 100%;
  height: 100%;
  display: flex;
  padding: var(--dl-space-space-unit);
  align-items: flex-start;
  flex-direction: column;
  background-size: cover;
  background-image: url('https://s3.us-east-2.amazonaws.com/triplebrooknursery.com/assets/plants1.jpg');
}
.home-text44 {
  color: var(--dl-color-gray-white);
  font-size: 1.5rem;
  font-weight: 600;
}
@media(max-width: 991px) {
  .home-navbar-interactive {
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
  }
  .home-icon {
    display: none;
  }
  .home-text03 {
    display: none;
  }
  .home-hero {
    gap: var(--dl-space-space-threeunits);
    height: auto;
    align-items: flex-start;
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    flex-direction: column;
  }
  .home-image {
    display: none;
  }
  .home-image1 {
    width: 100%;
    height: 757px;
    position: static;
    align-items: flex-end;
    aspect-ratio: 1;
    justify-content: flex-start;
  }
  .home-image2 {
    background-image: url('https://s3.us-east-2.amazonaws.com/triplebrooknursery.com/assets/plants10.jpg');
    background-position: right;
  }
  .home-information {
    gap: var(--dl-space-space-unit);
    padding-top: var(--dl-space-space-threeunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-fourunits);
  }
  .home-button6 {
    width: 100%;
    text-align: center;
  }
  .home-text10 {
    font-family: Lato;
  }
  .home-container02 {
    width: 40%;
  }
  .home-heading {
    color: rgb(18, 40, 33);
    height: 113px;
    font-size: 32px;
    font-style: normal;
    margin-top: var(--dl-space-space-sixunits);
    text-align: center;
    font-weight: 500;
    line-height: 40px;
    padding-left: var(--dl-space-space-threeunits);
  }
  .home-text11 {
    font-size: 32px;
    text-align: center;
    line-height: 23px;
  }
  .home-text12 {
    font-size: 32px;
  }
  .home-text13 {
    font-size: 32px;
  }
  .home-text14 {
    font-size: 32px;
  }
  .home-text15 {
    font-size: 32px;
  }
  .home-text17 {
    font-size: 24px;
    margin-top: var(--dl-space-space-fourunits);
    margin-left: var(--dl-space-space-unit);
    padding-left: var(--dl-space-space-threeunits);
  }
  .home-container03 {
    width: 60%;
    align-self: center;
  }
  .home-iframe {
    align-self: flex-start;
    margin-top: var(--dl-space-space-fourunits);
  }
  .home-gallery1 {
    grid-gap: var(--dl-space-space-unit);
  }
  .home-text34 {
    text-align: center;
  }
  .home-text35 {
    text-align: center;
  }
  .home-text36 {
    text-align: center;
  }
  .home-text37 {
    text-align: center;
  }
  .home-text38 {
    text-align: center;
  }
  .home-text39 {
    text-align: center;
  }
  .home-gallery2 {
    grid-gap: var(--dl-space-space-unit);
  }
  .home-text40 {
    text-align: center;
  }
  .home-text41 {
    text-align: center;
  }
  .home-text42 {
    text-align: center;
  }
  .home-text43 {
    text-align: center;
  }
  .home-text44 {
    text-align: center;
  }
}
@media(max-width: 767px) {
  .home-text {
    line-height: 50px;
  }
  .home-desktop-menu {
    display: none;
  }
  .home-burger-menu {
    width: 40px;
    height: 40px;
    display: flex;
    aspect-ratio: 1;
    border-radius: var(--dl-radius-radius-round);
    justify-content: center;
    background-color: #E6F9E0;
  }
  .home-hamburger {
    width: 16px;
    height: 8px;
  }
  .home-button3 {
    color: rgb(18, 40, 33);
    padding-left: 0px;
    background-color: transparent;
  }
  .home-button4 {
    color: rgb(18, 40, 33);
    padding-left: 0px;
    background-color: transparent;
  }
  .home-button5 {
    color: rgb(18, 40, 33);
    padding-left: 0px;
    background-color: transparent;
  }
  .home-social-bar {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .home-title {
    font-size: 36px;
    line-height: 58px;
  }
  .home-description {
    font-size: 16px;
    line-height: 24px;
  }
  .home-information {
    width: 100%;
    height: 1152px;
    align-items: center;
  }
  .home-container01 {
    display: block;
  }
  .home-container02 {
    width: 100%;
    height: 558px;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .home-heading {
    align-self: center;
    padding-left: 0px;
  }
  .home-text17 {
    align-self: center;
    margin-top: 0px;
  }
  .home-container03 {
    width: 100%;
    height: 391px;
  }
  .home-iframe {
    display: inline;
    align-self: center;
  }
  .home-gallery1 {
    height: auto;
    display: flex;
    padding: var(--dl-space-space-twounits);
    flex-direction: column;
  }
  .home-container05 {
    height: var(--dl-size-size-xxlarge);
  }
  .home-container07 {
    height: var(--dl-size-size-xxlarge);
  }
  .home-container09 {
    height: var(--dl-size-size-xxlarge);
    background-position: top;
  }
  .home-container11 {
    height: var(--dl-size-size-xxlarge);
    background-position: center;
  }
  .home-container13 {
    height: var(--dl-size-size-xxlarge);
    background-position: top;
  }
  .home-container15 {
    height: var(--dl-size-size-xxlarge);
    background-position: top;
  }
  .home-gallery2 {
    height: auto;
    display: flex;
    padding: var(--dl-space-space-twounits);
    flex-direction: column;
  }
  .home-container17 {
    height: var(--dl-size-size-xxlarge);
    background-position: center;
  }
  .home-container19 {
    height: var(--dl-size-size-xxlarge);
  }
  .home-container21 {
    height: var(--dl-size-size-xxlarge);
  }
  .home-container23 {
    height: var(--dl-size-size-xxlarge);
    background-position: center;
  }
  .home-container25 {
    height: var(--dl-size-size-xxlarge);
    background-position: top right;
  }
  .home-container27 {
    height: var(--dl-size-size-xxlarge);
  }
}
@media(max-width: 479px) {
  .home-social-bar {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .home-image1 {
    height: 100%;
  }
  .home-image2 {
    width: 449px;
    height: 577px;
    background-size: auto;
    background-position: top;
  }
  .home-information {
    height: 1065px;
  }
  .home-container02 {
    width: 100%;
    height: 498px;
  }
  .home-heading {
    margin-top: var(--dl-space-space-twounits);
  }
  .home-text17 {
    color: var(--dl-color-gray-black);
    font-size: 24px;
    align-self: center;
    margin-top: 0px;
    margin-left: 0px;
  }
  .home-text24 {
    font-size: 20px;
  }
  .home-text25 {
    font-size: 20px;
  }
  .home-iframe {
    width: 100%;
    margin-top: 0px;
  }
  .home-gallery1 {
    width: 100%;
    height: 100%;
    padding: var(--dl-space-space-unit);
  }
  .home-container05 {
    height: var(--dl-size-size-xxlarge);
    background-position: left;
  }
  .home-container06 {
    position: relative;
  }
  .home-container07 {
    height: var(--dl-size-size-xxlarge);
    align-items: flex-start;
  }
  .home-container09 {
    height: var(--dl-size-size-xxlarge);
  }
  .home-container11 {
    height: var(--dl-size-size-xxlarge);
    justify-content: flex-start;
    background-position: center;
  }
  .home-container13 {
    height: var(--dl-size-size-xxlarge);
  }
  .home-container15 {
    height: var(--dl-size-size-xxlarge);
    background-position: top;
  }
  .home-gallery2 {
    width: 100%;
    padding: var(--dl-space-space-unit);
  }
  .home-container16 {
    position: relative;
  }
  .home-container17 {
    height: var(--dl-size-size-xxlarge);
    background-position: center;
  }
  .home-container19 {
    height: var(--dl-size-size-xlarge);
  }
  .home-container21 {
    height: var(--dl-size-size-xxlarge);
  }
  .home-container23 {
    height: var(--dl-size-size-xlarge);
    background-position: center;
  }
  .home-container25 {
    height: var(--dl-size-size-xxlarge);
    background-position: center;
  }
  .home-container27 {
    height: var(--dl-size-size-xxlarge);
  }
}
